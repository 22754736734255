import type { AxiosInstance } from 'axios'
import { format, subYears } from 'date-fns'
import { LITHIUM } from '~/constants/urls'
import type { PriceIndexSummary } from '~/types/domain/priceIndexSummary'
import type { PricesResponse } from '~/types/dto/pricesResponse'
import type {
  PriceCategoryResponse,
  PricesSummaryResponse,
} from '~/types/dto/pricesSummaryResponse'
import type { PriceForecastsResponse } from '~/types/dto/priceForecastsResponse'
import type { PriceForecastScenario } from '~/types/domain/priceForecastScenario'
import { DateFormats } from '~/constants/general'
import type { PriceSummary } from '~/types/domain/priceSummary'
import type { EquityDataResponse } from '~/types/dto/equityDataResponse'
import type { PricesRangeResponse } from '~/types/dto/pricesRangeResponse'
import type { PricesIndexResponse } from '~/types/dto/pricesIndexResponse'

export const prices = (http: AxiosInstance) => ({
  getEquityData: () => http.get<EquityDataResponse>(LITHIUM.EQUITY_DATA),
  getPriceIndex: async (): Promise<any> => {
    return await http.post<PricesIndexResponse>(LITHIUM.PRICE_INDEX_V2)
  },
  getPriceRange: async (params?: { currency?: string }): Promise<any> => {
    return await http.get<PricesRangeResponse>(LITHIUM.PRICES_RANGE_V2, { params })
  },
  getDownloadUrlMethodology: () => http.get(LITHIUM.PRICES_DOWNLOAD_METHODOLOGY),
  getDownloadUrlForecasts: () => http.get(LITHIUM.PRICES_DOWNLOAD_FORECASTS),
  getSummaryByCategories: async (currencyISO: string): Promise<PriceIndexSummary[]> => {
    const currentDate = new Date()
    const startDate = format(subYears(currentDate, 1), DateFormats.yyyy_MM_dd)
    const endDate = format(currentDate, DateFormats.yyyy_MM_dd)

    const [summaryResponse, pricesResponse] = await Promise.all([
      http.post<PricesSummaryResponse>(LITHIUM.PRICES_SUMMARY_V2, {
        currency: currencyISO,
        priceTypes: ['Global Weighted Average', 'Price'],
      }),
      http.post<PricesResponse>(LITHIUM.PRICES_V2, {
        from: startDate,
        to: endDate,
        currency: currencyISO,
      }),
    ])

    const findSeriesBySummary = ({ name, shippingRoute, priceType }) => {
      return pricesResponse.data
        .reduce((accum, current) => {
          const { category, shippingRoute: serieShippingRoute, priceType: seriePriceType } = current
          const hasShippingRoute = shippingRoute && serieShippingRoute
          const isValidPriceType = priceType.name === seriePriceType.name
          const isValidName = category.name === name.replace(/ \(Spot\)$/, '')
          const isValidShippingRoute = hasShippingRoute
            ? serieShippingRoute?.name === shippingRoute?.name
            : isValidPriceType
          const validation = isValidName && isValidShippingRoute

          if (!validation) return accum

          return current
        })
        .series?.map(({ date, valueMid }) => ({ date, price: valueMid }))
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    }

    return summaryResponse.data.map(
      ({ frequency, name, series, isSustainable }: PriceCategoryResponse): PriceIndexSummary => ({
        values: series.map(
          ({
            data,
            grade,
            purity,
            shippingRoute,
            currency,
            priceType,
          }): {
            latestChange: number
            last: number
            yearToDate: number
            series: { date: any; price: any }[]
            today: number
            name: string
            currency: { id: string; name: string; iso: string; symbol: string }
            yearOnYear: number
          } => {
            const isSustainableName = isSustainable ? 'Sustainable ' : ''
            const shippingRouteName = shippingRoute ? shippingRoute.name : priceType.name
            const gradeName = grade ? `;${grade}` : ''
            const newName = `${isSustainableName}${shippingRouteName};${purity.name}${gradeName}`

            return {
              name: newName,
              currency,
              today: data.today,
              last: data.last,
              latestChange: data.latestChange?.value,
              yearOnYear: data.yearOnYear?.value,
              yearToDate: data.yearToDate?.value,
              series: findSeriesBySummary({ name, shippingRoute, priceType }),
            }
          },
        ),
        isSustainable,
        label: name,
        frequency,
      }),
    )
  },
  getPriceSummary: async (): Promise<PriceSummary[]> => {
    const { data } = await http.post<PricesSummaryResponse>(LITHIUM.PRICES_SUMMARY_V2, {
      categories: [
        { name: 'Lithium Carbonate', isSustainable: false },
        { name: 'Lithium Hydroxide', isSustainable: false },
      ],
      priceTypes: ['Global Weighted Average', 'Price'],
    })
    return data as PriceSummary[]
  },
  getPriceForecastsByType: async (
    type,
  ): Promise<{
    scenarios: PriceForecastScenario[]
    sourceData: {
      year: number
      quarter: number
    }
  }> => {
    const { data: response, $metadata } = await http.get<PriceForecastsResponse>(
      LITHIUM.PRICE_FORECASTS,
      {
        params: { priceType: type },
      },
    )
    const scenarios: PriceForecastScenario[] = []

    const getPeriodsByScenario = (scenario) => {
      return scenario.periods
        .sort((a, b) => a.order - b.order)
        .map((periodItem) => {
          const name = periodItem.period
          const region = periodItem.region
          const incoterm = periodItem.incoterm
          const years = periodItem.years
            .filter((yearItem) => yearItem.value)
            .map((yearItem) => ({
              year: yearItem.year,
              values: yearItem.value,
            }))
          const quarters = periodItem.years
            .filter((yearItem) => yearItem.quarters?.length > 0)
            .map((yearItem) => ({
              year: yearItem.year,
              values: yearItem.quarters,
            }))
          return { name, years, quarters, region, incoterm }
        })
    }

    response.forEach((item) => {
      item.scenarios.forEach((scenario) => {
        const currentScenarioIndex = scenarios.findIndex(
          (scenarioItem) => scenarioItem.name === scenario.scenario,
        )

        if (currentScenarioIndex === -1) {
          scenarios.push({
            name: scenario.scenario,
            products: [{ name: item.product, periods: getPeriodsByScenario(scenario) }],
          })
          return
        }

        const currentScenario = scenarios[currentScenarioIndex]
        const currentProductIndex = currentScenario.products.findIndex(
          (productItem) => productItem.name === item.product,
        )

        if (currentProductIndex === -1) {
          currentScenario.products.push({
            name: item.product,
            periods: getPeriodsByScenario(scenario),
          })
        }
      })
    })
    return { scenarios, sourceData: $metadata.sourceData }
  },
  getPriceHeaders: () => http.get(LITHIUM.PRICES_HEADERS),
})
